<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>

         <v-col cols="12" md="4">
          <v-select
            label="Select Card"
            v-model="SelCard"
            :items="Card"
            item-text="card_name"
            item-value="card_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

       <v-spacer></v-spacer>

        <v-col cols="12" md="1">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>

 <v-col cols="12" md="3" class="pl-10">
    <v-badge
        
        color="error"
        :content="totalprintqu"
        
      >
          <v-btn medium color="primary" @click="btnqueue">
            <v-icon left>mdi-printer</v-icon>PRINT QUEUE</v-btn
          >

           </v-badge>
          
        </v-col>

      </v-row>
    </v-card>
 <v-card class="pa-1 mb-2" flat>
    <v-data-table
      v-model="TDselected"
      :headers="headers1"
      :items="desserts"
     
      :search="search"
      item-key="application_id"
      show-select
      loading-text="Loading... Please wait"
      style="border-radius: 0px"
    >
      <template v-slot:top>
        <v-card style="position: sticky;top:0px;z-index:8;">
       <v-row class="mt-2 mb-2">
         <v-col cols="12" md="9">
           <div v-if="TDselected.length > 0">
          <v-row>
            <v-col cols="12" md="12">
                <v-alert prominent
      type="info"  dense >
                    <v-row align="center">
        <v-col>
          {{TDselected.length}} No. Application send to Re-print
        </v-col>

  <v-col cols="12" md="5">
              <v-select
                label="Select Reason"
                v-model="SelReasonForUpdate"
                :items="ResonForUpdate"
                item-text="reason_name"
                item-value="reason_id"
                outlined
                hide-details
                dense
                class="grey"
              ></v-select>
            </v-col>


           

        <v-col class="shrink">
          <v-btn @click="btnsave">SEND TO RE-PRINT</v-btn>
        </v-col>
      </v-row>
                </v-alert>
             
            </v-col>
           

           
           
          </v-row>
        </div>
         </v-col>
         <v-col cols="12" md="3">
            <v-text-field
            v-model="search"
            dense
            solo-inverted
            flat
            label="Search"
            hide-details
          ></v-text-field>
         </v-col>
       </v-row>
        </v-card>
      </template>
      <!-- @click="BtnApprove(index)" -->
      <template v-slot:item.application_id="{ item }">
        <tr>
          <!-- <td>
            <v-checkbox
              v-model="chkselected"
              :value="item.application_id"
              @click="chkclick"
            ></v-checkbox>
          </td> -->
          <td style="text-align:left;width:100px">{{ item.app_id }}</td>
          <td style="text-align:left;width:65px">
            <img
              :src="$apiPath + item.photo"
              height="60px"
              width="60px"
              style="border-radius: 10px; border: 1px solid #ccc"
              type="button"
            />
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
        No Record(s)
      </template>
    </v-data-table>
 </v-card>



  <v-navigation-drawer
      v-model="sheet"
      right persistent temporary  fixed style="width: 100%; max-width:900px; margin: auto;z-index:11;"
    >
      <v-sheet class="text-center">
        <div class="py-3 text-left">
          <v-card
            flat
            max-width="900px"
            style="width: 100%; margin: auto"
            class="pa-3"
          >
            <v-toolbar color="transparent" flat>
              <v-icon>mdi-edit</v-icon>{{ Printflag }}
              <v-spacer></v-spacer>

              <v-btn icon light>
                <v-icon color="red darken-2" @click="sheet = !sheet"
                  >mdi-close</v-icon
                >
              </v-btn>
            </v-toolbar>

             <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-data-table
      :headers="headers"
      :items="desserts1"
      :search="searchprintqueue"
      loading-text="Loading... Please wait"
      style="border-radius: 0px"
    >
<template v-slot:top>
  <v-col cols="12" md="12">
            <v-text-field
            v-model="searchprintqueue"
            dense
           
            
            label="Search"
            hide-details
          ></v-text-field>
         </v-col>
</template>

     <template v-slot:item.application_id="{ item }">
     
        <tr>
          <!-- <td>
            <v-checkbox
              v-model="chkselected"
              :value="item.application_id"
              @click="chkclick"
            ></v-checkbox>
          </td> -->
          <td style="text-align:left;width:100px">{{item.app_id}}</td>
          <td style="text-align:left;width:65px">
            <img
              :src="$apiPath + item.photo"
              height="60px"
              width="60px"
              style="border-radius: 10px; border: 1px solid #ccc"
              type="button"
            />
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
        No Record(s)
      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
             </v-container>
          
          </v-card>
        </div>
      </v-sheet>
    </v-navigation-drawer>
    

    <v-snackbar v-model="snackbar" timeout="1000"  top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay" style="z-index:10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  components: {
    
  },
  data() {
    return {
      totalprintqu:"0",
       Printflag:"PRINT IN QUEUE",
      searchprintqueue:"",
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: false,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "",
      Status: [],

      SelstatusForUpade: "",
      StatusForUpdate: [],

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
     postforGetdata:{
       event_id: "",
       venue_id: "",
       vendor_id: "",
     },

        SelCard: "",
      Card: [],
    
      postBulkUpdate: {
        application_id: [],
        event_id: "",
        venue_id: "",
        status_id:"",
        reason_id:"",
      },

 headers: [
        {
          text: "App ID",
          align: "left",
           value: "application_id",
           class: "blue lighten-5",
          sortable: false,
        },

         {
          text: "Name",
          value: "firstName",
          align: "left",
          class: "blue lighten-5",
        },

      
      {
          text: "Publication",
          value: "mediaName",
          align: "left",
          class: "blue lighten-5",
        },


         {
          text: "Designation",
          value: "role_name",
          align: "left",
          class: "blue lighten-5",
        },


         {
          text: "AppStatus",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "ReprintCount",
          value: "reprintCount",
          align: "left",
          class: "blue lighten-5",
        },

 
         {
          text: "Card Name",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },


       

       
       


       
      ],
       headers1: [
        {
          text: "App ID",
          align: "left",
           value: "application_id",
           class: "blue lighten-5",
          sortable: false,
        },

         {
          text: "Name",
          value: "firstName",
          align: "left",
          class: "blue lighten-5",
        },

      
      {
          text: "Publication",
          value: "mediaName",
          align: "left",
          class: "blue lighten-5",
        },


         {
          text: "Designation",
          value: "role_name",
          align: "left",
          class: "blue lighten-5",
        },
 
         {
          text: "Card Name",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "QR Code",
          value: "qrcode",
          align: "left",
          class: "blue lighten-5",
        },

 {
          text: "ReprintCount",
          value: "reprintCount",
          align: "left",
          class: "blue lighten-5",
        },

      ],
      desserts: [],
      desserts1:[],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
   // sessionStorage.display_event_name = "IPL T20 2021";
   // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    //sessionStorage.EVENTID = "1";
   // sessionStorage.VENUEID = "1";
   // sessionStorage.VENDORID = "1";

    this.$store.commit("setPageTitle", "Media Appication Reprint");
    this.$store.commit("setEventName", sessionStorage.display_event_name);
    this.$store.commit("setVenueName", sessionStorage.display_venue_name);

  this.bindCard();
  this.ReloadQuForpageload(this.$store.state.eventInfo.EventId, this.$store.state.eventInfo.VenueId);
      this.bindReson("7");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    
   bindReson: async function (StatusID) {
      await this.$axios
        .get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" + StatusID)
        .then((res) => {
          this.ResonForUpdate = res.data.result;
        });
    },
   


  

    btn_reload: function () {
      this.Reload(this.$store.state.eventInfo.EventId, this.$store.state.eventInfo.VenueId);
    },

    btnsearch: function () {
     
       if(this.SelCard.length<=0){
        this.showSnackbar("error", "Select Card !");
      }
      else{
      this.desserts = [];
      this.chkselected = [];
      this.appstatus = this.Selstatus;
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        "7",
        this.SelCard
      );
      }
    },

        btnqueue: function () {
       this.ReloadQu(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
        
        
      );
      this.sheet=true;
    },

   

     bindCard: async function () {
          this.overlay=true;
      await this.$axios
        .get(
         // "Media/mediaCategory"
         "Cards/GetByVenue/" + this.$store.state.eventInfo.EventId + "/media"
        )
        .then((res) => {
          if(res.data.result.length>0){
          this.Card = res.data.result;
          this.Card.splice(0,0,{card_id: "0", card_name: "All" });
          this.overlay=false;
          }
          
        });
    },

  ReloadQuForpageload: async function (event_id, venue_id) {
  // this.this.desserts1=[];
   //this.totalprintqu="";
      await this.$axios
        .get(
            "PrintMedia/Pending/" +
            event_id +
            "/" +
            venue_id
        )
        .then((res) => {
          this.desserts_load = res.data.result;

            this.desserts1 = this.desserts_load.filter(
            (task) => task.printflag == "Y"
          );
this.totalprintqu=this.desserts1.length;
        })
        .catch()
        .finally();
    },

   
  ReloadQu: async function (event_id, venue_id) {
    //  this.loadingstatus = true;
       this.overlay=true;
                
await this.$axios
        .get(
            "PrintMedia/Pending/" +
            event_id +
            "/" +
            venue_id
        )
        .then((res) => {
           console.log(res.data.result) ;
          this.desserts_load = res.data.result;

        
            this.desserts1 = this.desserts_load.filter(
            (task) => task.printflag == "Y"
          );
this.totalprintqu=this.desserts1.length;
          this.loadingstatus = false;
           this.overlay=false;
        })
        .catch()
        .finally();
    },
   

   

    Reload: async function (event_id, venue_id,status_id,card_id) {
    //  this.loadingstatus = true;
    //alert("dddd" + event_id + "/" + venue_id + "/" + status_id + "/" + card_id);
       this.overlay=true;
      await this.$axios
        .get(
            "PrintMedia/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            status_id + "/" + card_id
        )
        .then((res) => {
          console.log("Test Reprint");
console.log(res.data.result);
          this.desserts_load = res.data.result;
         // this.desserts= res.data.result;

              this.desserts = this.desserts_load.filter(
                (task) => task.printflag == "N"
              );

          

          this.loadingstatus = false;
           this.overlay=false;
        })
        .catch()
        .finally();
    },

    btnsave: async function () {
//this.$store.state.eventInfo.EventId, this.$store.state.eventInfo.VenueId


this.postBulkUpdate.application_id=[],

 this.TDselected.forEach((el) => {
        this.postBulkUpdate.application_id.push(el.application_id);
      });


      this.postBulkUpdate.event_id = this.$store.state.eventInfo.EventId;
      this.postBulkUpdate.venue_id = this.$store.state.eventInfo.VenueId;
      this.postBulkUpdate.status_id = "7";
      this.postBulkUpdate.reason_id = this.SelReasonForUpdate;
    
      await this.$axios
        .post(
          "PrintMedia/RePrint",
          this.postBulkUpdate
        )
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "Data Updated!");
 this.Reload(this.$store.state.eventInfo.EventId,this.$store.state.eventInfo.VenueId,"7",this.SelCard);
  this.desserts = [];
      this.chkselected = [];
      this.TDselected=[];
          }
        })
        .catch()
        .finally();
    },

   
  },
};
</script>

<style>
</style>